/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
// import { format, isValid, parse } from 'date-fns';
import React from 'react';
import { format, isValid, parse } from 'date-fns';
import { useMantineColorScheme } from '@mantine/core';
import {
  BarChart,
  Bar,
  XAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
// import { assetPrefix, renderNumber } from '../../../utilities/general';
import styles from './CompanyDataV2.module.css';
import { assetPrefix } from '../../../utilities/general';

export default function StakedBarChart(props) {
  const {
    chartName, days, data,
  } = props;
  const { colorScheme } = useMantineColorScheme();

  const DateFormatter = (date) => {
    const d = parse(date, 'yyyy-MM-dd', new Date());
    return isValid(d) ? format(d, 'LLL dd') : null;
  };

  const barKeys = Array.from(
    new Set(data.flatMap((item) => item.assets.map((value) => value.asset))),
  );

  return (
    <div className={`${styles.paddingLeft19} flex flex-col py-3 px-3 h-full`}>
      <div className="flex justify-between">
        <div className="pt-0">
          <div className={`${styles.HeadingText} ${colorScheme === 'dark' ? 'text-[#beb9b9]' : 'text-[#3A3A3A]'} flex items-baseline gap-1`}>
            {`${chartName}`}
            <span className="text-xs opacity-70 flex !items-center">{` - ${days}`}</span>
          </div>
        </div>
        <div className="flex gap-2">
          <div className="flex w-7 h-7 rounded-full bg-[#0072C3] justify-center items-center">
            <div className="flex w-5 h-5 rounded-full bg-white justify-center items-center">
              <img
                className="w-4 h-4 rounded-full"
                src={`${assetPrefix}/images/eth.svg`}
                alt="ETH"
              />
            </div>
          </div>
          <div className="flex w-7 h-7 rounded-full bg-[#33B1FF] justify-center items-center">
            <div className="flex w-5 h-5 rounded-full bg-white justify-center items-center">
              <img
                className="w-4 h-4 rounded-full"
                src={`${assetPrefix}/images/bch.svg`}
                alt="BCH"
              />
            </div>
          </div>
          <div className="flex w-7 h-7 rounded-full bg-[#F9A61A] justify-center items-center">
            <div className="flex w-5 h-5 rounded-full bg-white justify-center items-center">
              <img
                className="w-4 h-4 rounded-full"
                src={`${assetPrefix}/images/xrp.svg`}
                alt="XRP"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-[100%] h-full mt-5">
        <ResponsiveContainer width="99%" height={175}>
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis
              dataKey="date"
              tickLine={false}
              tickFormatter={DateFormatter}
              tickSize={6}
              interval={days === '30 Days' ? 5 : 'preserveStartEnd'}
              style={{ fontSize: 10 }}
              stroke="#A3AED0"
              axisLine={false}
              padding={{ left: 10, right: 5 }}
            />
            <Tooltip />
            {barKeys.map((key, index) => (
              <Bar
                key={key}
                dataKey={(entry) => {
                  const valueObj = entry.assets.find((v) => v.asset === key);
                  return valueObj ? valueObj.value : 0;
                }}
                stackId="a"
                fill={['#0072C3', '#33B1FF', '#F9A61A', '#ff0000', '#00ff00'][index % 5]}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
