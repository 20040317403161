/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
// import { format, isValid, parse } from 'date-fns';
import React, { useState } from 'react';

import {
  Area,
  AreaChart, ResponsiveContainer, Tooltip, XAxis,
} from 'recharts';
import { format, isValid, parse } from 'date-fns';
import { assetPrefix, renderNumber } from '../../../../utilities/general';
import styles from '../CompanyDataV2.module.css';
// import { InvestmentDummy } from './dummy';

const CustomizedCursor = (x) => {
  const { points, height } = x;
  return (<line x1={points[0].x - 2} y1={116} x2={points[0].x - 2} y2={height} stroke="#026DB08C" strokeWidth={1} strokeDasharray={3} />

  );
};
export default function InvestmentLineCharts(props) {
  const {
    chartName, total, changeInPercentage, days, data,
  } = props;
  const [height, setHeight] = useState(null);

  const DateFormatter = (date) => {
    const d = parse(date, 'yyyy-MM-dd', new Date());
    return isValid(d) ? format(d, 'LLL dd') : null;
  };

  const renderActiveDot = (propsss) => {
    const { cx, cy, value } = propsss;
    setHeight(cy);
    return (
      <svg
        x={cx - 27}
        y={cy - 35}
        width="200"
        height="68"
        viewBox="0 0 200 68"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="53" height="27" viewBox="0 0 53 27" fill="none">
          <path d="M0.522461 6.03466C0.522461 2.72095 3.20875 0.034668 6.52246 0.034668H46.3206C49.6344 0.034668 52.3206 2.72096 52.3206 6.03467V16.9779C52.3206 20.1616 49.7398 22.7425 46.5561 22.7425H34.7528C31.8751 22.7425 29.1336 23.9684 27.215 26.1132C26.7918 26.5863 26.0513 26.5863 25.6281 26.1132C23.7095 23.9684 20.968 22.7425 18.0903 22.7425H6.28701C3.10334 22.7425 0.522461 20.1616 0.522461 16.9779V6.03466Z" fill="#026DB0" />
        </svg>
        <text className={styles.tooltipText} textAnchor="middle" dy={15} dx={27}>
          {renderNumber(value[1], true)}
        </text>
        <rect
          x="21.3"
          y="30.4"
          width="8"
          height="8"
          rx="7"
          fill="#fff"
          stroke="blue"
          strokeWidth="2"
        />
      </svg>
    );
  };
  return (
    <div className={`${styles.paddingLeft19} flex flex-col py-3 px-3`}>
      <div className="pt-0">
        <div className={`${styles.HeadingText} ${chartName === 'Profit Generation' && '!text-[#fff]'} flex items-baseline gap-1`}>
          {`${chartName}`}
          <span className="text-xs opacity-70 flex !items-center">{` - ${days}`}</span>
        </div>
        {
          (total && changeInPercentage) && (
            <div className="flex gap-4">
              <span className={`text-2xl font-bold ${chartName === 'Profit Generation' ? '!text-white' : 'text-[#3A3A3A]'}`}>{total ? `${renderNumber(total)}` : '-'}</span>
              <div className="flex gap-2 items-center">
                <span>
                  {(changeInPercentage)
                    ? (
                      <img
                        src={`${assetPrefix}/images/GreenArrow.svg`}
                        alt="High"
                        className="max-w-fit w-3 h-3"
                      />
                    )
                    : (
                      <img
                        src={`${assetPrefix}/images/RedArrow.svg`}
                        alt="Low"
                        className="max-w-fit w-3 h-3"
                      />
                    )}
                </span>
                <span className={`${styles.changeInPercentage} ${changeInPercentage > 0 ? 'text-green-500' : 'text-red-500'}`}>{`${changeInPercentage ? renderNumber(changeInPercentage) : '+2.45'}%`}</span>
              </div>
            </div>
          )
        }

      </div>
      <div className="w-[100%] h-full">
        <ResponsiveContainer width="99%" height={213}>
          <AreaChart
            data={data}
            margin={{
              top: 25,
              bottom: 10,
              left: 20,
              right: 20,
            }}
          >
            {/* <defs>
              <linearGradient id="investmentColor" x1="19
              3.5" y1="0" x2="193.5" y2="101" gradientUnits="userSpaceOnUse">
                <stop stopColor="#0093EF" stopOpacity="0.54" />
                <stop offset="1" stopColor="#0093EF" stopOpacity="0" />
              </linearGradient>
            </defs> */}
            <defs>
              <linearGradient id="investmentColor" x1="0" y1="0" x2="0" y2="1">
                <stop stopColor="#33B1FF" stopOpacity={0.7} />
                <stop offset="100%" stopColor="#007AFF" stopOpacity={0} />
              </linearGradient>
            </defs>
            <Tooltip
              content={() => null}
              cursor={<CustomizedCursor height={height} />}
            />

            <XAxis
              dataKey="date"
              tickLine={false}
              tickFormatter={DateFormatter}
              tickSize={6}
              interval={days === '30 Days' ? 5 : 'preserveStartEnd'}
              style={{ fontSize: 10 }}
              stroke="#A3AED0"
              axisLine={false}
              padding={{ left: 10, right: 5 }}
            />
            <Area
              dataKey="value"
              stroke="#33B1FF"
              fill="URL(#investmentColor)"
              strokeWidth={3}
              activeDot={renderActiveDot}
              dot={false}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
