/* eslint-disable import/prefer-default-export */
export const InvestmentDummy = [
  {
    date: '2023-11-5',
    value: 2000,
  },
  {
    date: '2023-11-30',
    value: 8592.77,
  },
  {
    date: '2023-12-15',
    value: 4000.49,
  },
  {
    date: '2023-12-31',
    value: 7438.6,
  },
  {
    date: '2024-01-15',
    value: 6002.49,
  },
  {
    date: '2024-01-30',
    value: 4072.49,
  },
];

export const PieChartDummy = [
  {
    name: 'Back to Company',
    value: 400,
  },
  {
    name: 'Back to Company',
    value: 300,
  }, {
    name: 'Back to Company',
    value: 500,
  }, {
    name: 'Back to Company',
    value: 480,
  },
];

export const dataAssetValue = [
  {
    date: '2024-10-15',
    value: 3000,
  },
  {
    date: '2024-10-16',
    value: 5000,
  },
  {
    date: '2024-10-17',
    value: 7780,
  },
  {
    date: '2024-10-18',
    value: 9090,
  },
  {
    date: '2024-10-19',
    value: 10290,
  },
  {
    date: '2024-10-20',
    value: 11490,
  },
  {
    date: '2024-10-21',
    value: 13590,
  },
  {
    date: '2024-10-22',
    value: 16490,
  },
  {
    date: '2024-10-23',
    value: 18490,
  },
  {
    date: '2024-10-24',
    value: 20490,
  },
  {
    date: '2024-10-25',
    value: 22490,
  },
  {
    date: '2024-10-26',
    value: 24490,
  },
  {
    date: '2024-10-27',
    value: 26490,
  },
  {
    date: '2024-10-28',
    value: 28490,
  },
  {
    date: '2024-10-29',
    value: 30490,
  },
  {
    date: '2024-10-30',
    value: 30490,
  },
  {
    date: '2024-10-31',
    value: 30490,
  },
  {
    date: '2024-11-01',
    value: 34490,
  },
  {
    date: '2024-11-02',
    value: 34490,
  },
  {
    date: '2024-11-03',
    value: 38490,
  },
  {
    date: '2024-11-04',
    value: 39490,
  },
  {
    date: '2024-11-05',
    value: 41490,
  },
  {
    date: '2024-11-06',
    value: 45490,
  },
  {
    date: '2024-11-07',
    value: 49490,
  },
  {
    date: '2024-11-08',
    value: 53490,
  },
  {
    date: '2024-11-09',
    value: 56490,
  },
  {
    date: '2024-11-10',
    value: 58490,
  },
  {
    date: '2024-11-11',
    value: 60490,
  },
  {
    date: '2024-11-12',
    value: 61990,
  },
  {
    date: '2024-11-13',
    value: 65490,
  },
  {
    date: '2024-11-14',
    value: 69990,
  },
];

export const stakedBarChartdata = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export const stakedByAssets = [
  {
    date: '2024-10-24',
    asset: 'SOL',
    value: 200,
  },
  {
    date: '2024-10-25',
    asset: 'ETH',
    value: 500,
  },
  {
    date: '2024-10-26',
    asset: 'ETH',
    value: 300,
  },
  {
    date: '2024-10-27',
    asset: 'SUI',
    value: 300,
  },
  {
    date: '2024-10-28',
    asset: 'ETH',
    value: 500,
  },
  {
    date: '2024-10-29',
    asset: 'ETH',
    value: 300,
  },
  {
    date: '2024-10-30',
    asset: 'SUI',
    value: 300,
  },
  {
    date: '2024-10-31',
    asset: 'ETH',
    value: 500,
  },
  {
    date: '2024-11-01',
    asset: 'ETH',
    value: 300,
  },
  {
    date: '2024-11-02',
    asset: 'SUI',
    value: 300,
  },
  {
    date: '2024-11-03',
    asset: 'ETH',
    value: 300,
  },
  {
    date: '2024-11-04',
    asset: 'SUI',
    value: 300,
  },
  {
    date: '2024-11-05',
    asset: 'SOL',
    value: 250,
  },
  {
    date: '2024-11-06',
    asset: 'ETH',
    value: 300,
  },
  {
    date: '2024-11-07',
    asset: 'SUI',
    value: 300,
  },
  {
    date: '2024-11-08',
    asset: 'SOL',
    value: 250,
  },
  {
    date: '2024-11-09',
    asset: 'ETH',
    value: 300,
  },
  {
    date: '2024-11-10',
    asset: 'SUI',
    value: 300,
  },
  {
    date: '2024-11-11',
    asset: 'SOL',
    value: 250,
  },
  {
    date: '2024-11-12',
    asset: 'ETH',
    value: 300,
  },
  {
    date: '2024-11-13',
    asset: 'SUI',
    value: 300,
  },
  {
    date: '2024-11-14',
    asset: 'SOL',
    value: 250,
  },
];

export const stakingByAsset = [
  {
    date: '2024-10-17',
    assets: [
      {
        asset: 'SOL',
        value: 20,
      },
      {
        asset: 'SUI',
        value: 150,
      },
      {
        asset: 'ETH',
        value: 70,
      },
    ],
  },
  {
    date: '2024-10-18',
    assets: [
      {
        asset: 'SOL',
        value: 70,
      },
      {
        asset: 'SUI',
        value: 20,
      },
      {
        asset: 'ETH',
        value: 10,
      },
    ],
  },
  {
    date: '2024-10-19',
    assets: [
      {
        asset: 'SOL',
        value: 0,
      },
      {
        asset: 'SUI',
        value: 10,
      },
      {
        asset: 'ETH',
        value: 200,
      },
    ],
  },
  {
    date: '2024-10-20',
    assets: [
      {
        asset: 'SOL',
        value: 160,
      },
      {
        asset: 'SUI',
        value: 90,
      },
      {
        asset: 'ETH',
        value: 400,
      },
    ],
  },
  {
    date: '2024-10-21',
    assets: [
      {
        asset: 'SOL',
        value: 80,
      },
      {
        asset: 'SUI',
        value: 10,
      },
      {
        asset: 'ETH',
        value: 180,
      },
    ],
  },
  {
    date: '2024-10-22',
    assets: [
      {
        asset: 'SOL',
        value: 600,
      },
      {
        asset: 'SUI',
        value: 210,
      },
      {
        asset: 'ETH',
        value: 140,
      },
    ],
  },
  {
    date: '2024-10-23',
    assets: [
      {
        asset: 'SOL',
        value: 10,
      },
      {
        asset: 'SUI',
        value: 350,
      },
      {
        asset: 'ETH',
        value: 700,
      },
    ],
  },
  {
    date: '2024-10-24',
    assets: [
      {
        asset: 'SOL',
        value: 300,
      },
      {
        asset: 'SUI',
        value: 650,
      },
      {
        asset: 'ETH',
        value: 20,
      },
    ],
  },
  {
    date: '2024-10-25',
    assets: [
      {
        asset: 'SOL',
        value: 600,
      },
      {
        asset: 'SUI',
        value: 700,
      },
      {
        asset: 'ETH',
        value: 20,
      },
    ],
  },
  {
    date: '2024-10-26',
    assets: [
      {
        asset: 'SOL',
        value: 200,
      },
      {
        asset: 'SUI',
        value: 300,
      },
      {
        asset: 'ETH',
        value: 800,
      },
    ],
  },
  {
    date: '2024-10-27',
    assets: [
      {
        asset: 'SOL',
        value: 800,
      },
      {
        asset: 'SUI',
        value: 600,
      },
      {
        asset: 'ETH',
        value: 400,
      },
    ],
  },
  {
    date: '2024-10-28',
    assets: [
      {
        asset: 'SOL',
        value: 999,
      },
      {
        asset: 'SUI',
        value: 250,
      },
      {
        asset: 'ETH',
        value: 900,
      },
    ],
  },
  {
    date: '2024-10-29',
    assets: [
      {
        asset: 'SOL',
        value: 780,
      },
      {
        asset: 'SUI',
        value: 230,
      },
      {
        asset: 'ETH',
        value: 555,
      },
    ],
  },
  {
    date: '2024-10-30',
    assets: [
      {
        asset: 'SOL',
        value: 450,
      },
      {
        asset: 'SUI',
        value: 380,
      },
      {
        asset: 'ETH',
        value: 840,
      },
    ],
  },
  {
    date: '2024-10-31',
    assets: [
      {
        asset: 'SOL',
        value: 840,
      },
      {
        asset: 'SUI',
        value: 480,
      },
      {
        asset: 'ETH',
        value: 250,
      },
    ],
  },
  {
    date: '2024-11-01',
    assets: [
      {
        asset: 'SOL',
        value: 120,
      },
      {
        asset: 'SUI',
        value: 560,
      },
      {
        asset: 'ETH',
        value: 890,
      },
    ],
  },
  {
    date: '2024-11-02',
    assets: [
      {
        asset: 'SOL',
        value: 450,
      },
      {
        asset: 'SUI',
        value: 680,
      },
      {
        asset: 'ETH',
        value: 444,
      },
    ],
  },
  {
    date: '2024-11-03',
    assets: [
      {
        asset: 'SOL',
        value: 190,
      },
      {
        asset: 'SUI',
        value: 700,
      },
      {
        asset: 'ETH',
        value: 690,
      },
    ],
  },
  {
    date: '2024-11-04',
    assets: [
      {
        asset: 'SOL',
        value: 444,
      },
      {
        asset: 'SUI',
        value: 999,
      },
      {
        asset: 'ETH',
        value: 359,
      },
    ],
  },
  {
    date: '2024-11-05',
    assets: [
      {
        asset: 'SOL',
        value: 780,
      },
      {
        asset: 'SUI',
        value: 340,
      },
      {
        asset: 'ETH',
        value: 550,
      },
    ],
  },
  {
    date: '2024-11-06',
    assets: [
      {
        asset: 'SOL',
        value: 340,
      },
      {
        asset: 'SUI',
        value: 800,
      },
      {
        asset: 'ETH',
        value: 129,
      },
    ],
  },
  {
    date: '2024-11-07',
    assets: [
      {
        asset: 'SOL',
        value: 450,
      },
      {
        asset: 'SUI',
        value: 800,
      },
      {
        asset: 'ETH',
        value: 120,
      },
    ],
  },
  {
    date: '2024-11-08',
    assets: [
      {
        asset: 'SOL',
        value: 100,
      },
      {
        asset: 'SUI',
        value: 340,
      },
      {
        asset: 'ETH',
        value: 670,
      },
    ],
  },
  {
    date: '2024-11-09',
    assets: [
      {
        asset: 'SOL',
        value: 459,
      },
      {
        asset: 'SUI',
        value: 888,
      },
      {
        asset: 'ETH',
        value: 123,
      },
    ],
  },
  {
    date: '2024-11-10',
    assets: [
      {
        asset: 'SOL',
        value: 670,
      },
      {
        asset: 'SUI',
        value: 900,
      },
      {
        asset: 'ETH',
        value: 530,
      },
    ],
  },
  {
    date: '2024-11-11',
    assets: [
      {
        asset: 'SOL',
        value: 340,
      },
      {
        asset: 'SUI',
        value: 670,
      },
      {
        asset: 'ETH',
        value: 550,
      },
    ],
  },
  {
    date: '2024-11-10',
    assets: [
      {
        asset: 'SOL',
        value: 560,
      },
      {
        asset: 'SUI',
        value: 900,
      },
      {
        asset: 'ETH',
        value: 220,
      },
    ],
  },
  {
    date: '2024-11-11',
    assets: [
      {
        asset: 'SOL',
        value: 440,
      },
      {
        asset: 'SUI',
        value: 670,
      },
      {
        asset: 'ETH',
        value: 888,
      },
    ],
  },
  {
    date: '2024-11-12',
    assets: [
      {
        asset: 'SOL',
        value: 299,
      },
      {
        asset: 'SUI',
        value: 900,
      },
      {
        asset: 'ETH',
        value: 400,
      },
    ],
  },
  {
    date: '2024-11-13',
    assets: [
      {
        asset: 'SOL',
        value: 340,
      },
      {
        asset: 'SUI',
        value: 890,
      },
      {
        asset: 'ETH',
        value: 230,
      },
    ],
  },
  {
    date: '2024-11-14',
    assets: [
      {
        asset: 'SOL',
        value: 400,
      },
      {
        asset: 'SUI',
        value: 150,
      },
      {
        asset: 'ETH',
        value: 450,
      },
    ],
  },
];

export const dummyDataAssets = [
  {
    date: '2024-10-17',
    assets:
    {
      SOL: 20,
      SUI: 150,
      ETH: 70,
      // BTC: 40,
      // ETV: 78,
    },
  },
  {
    date: '2024-10-18',
    assets:
    {
      SOL: 60,
      SUI: 200,
      ETH: 30,
    },
  },
  {
    date: '2024-10-19',
    assets:
    {
      SOL: 90,
      SUI: 420,
      ETH: 40,
    },
  },
  {
    date: '2024-10-20',
    assets:
    {
      SOL: 90,
      SUI: 420,
      ETH: 40,
    },
  },
];
